import React from 'react'
import cc from 'classcat'
import { ErrorMessage } from 'formik'
import { FormError } from './form-error'

export const FormInput = ({ field, form, ...props }) => {
	const hasError = Boolean(form.errors[field.name])
	const wrapperClass = cc([
		'floating-input relative ',
		{
			'border-red-600 focus-within:border-red-600 text-red-600 has-error':
				hasError,
			'border-brand-charcoal-light focus-within:border-brand-charcoal-dark':
				!hasError
		}
	])

	return (
		<div>
			<div className={wrapperClass}>
				<input
					type={props.type}
					placeholder={props.placeholder ? props.placeholder : ''}
					{...field}
					id={field.name}
					className={`${props.inputclassname} ${
						props.disabled && 'text-brand-light-grey-3'
					}`}
					{...props}
				/>
				<label htmlFor={field.name} className={props.labelclassname}>
					{props.label}
				</label>
			</div>
			<ErrorMessage component={FormError} name={field.name} />
		</div>
	)
}
