import cc from 'classcat'
import { Field, Form, Formik } from 'formik'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Link from 'next/link'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { Info } from '@phosphor-icons/react'
import * as Yup from 'yup'

import { Loading } from '../atoms/loading'
import { CtaButton } from '../components/cta-button'
import { FormInput } from '../components/form-input'
import Layout from '../components/layout'
import { Seo } from '../components/seo'
import ConfirmPhoneNumberDialog from '../components/confirm-phone-nbr-dialog'
import {
	postPersonAuthenticate,
	resetAuthenticationStatus,
	resetVerifyLoginOTPStatus,
	verifyLoginOTP,
	setPhoneNumber
} from '../slices/rootSlice'
import { HEADER, PERSON, TRANSLATIONS } from '../util/constants'
import numberUtil from '../util/number'

function Login({ data }) {
	const { t } = useTranslation(TRANSLATIONS)
	const formikLoginRef = useRef()
	const dispatch = useDispatch()
	const router = useRouter()
	let isLoggedIn = useSelector((state) => state.root.isLoggedIn)
	let dashboardType = useSelector((state) => state.root.user.dashboardType)
	let phone_number = useSelector((state) => state.root.user.phone_number)
	let getAuthenticationStatus = useSelector(
		(state) => state.root.getAuthenticationStatus
	)
	const getVerifyLoginOTPStatus = useSelector(
		(state) => state.root.getVerifyLoginOTPStatus
	)
	const loginErrorCode = useSelector((state) => state.root.loginErrorCode)
	const onboard_status = useSelector((state) => state.root.user.onboard_status)
	const [isVerifyDialogOpen, setIsVerifyDialogOpen] = useState(false)
	const [showLoginError, setShowLoginError] = useState(false)
	let [showSubmitLoading, setShowSubmitLoading] = useState(false)
	let initialValues = {
		phone_number: ''
	}

	const validationSchema = Yup.object().shape({
		phone_number: Yup.string().required(' ').nullable().length(14, ' ')
	})

	useEffect(() => {
		// If user is already logged in, take them to the dashboard
		if (!isLoggedIn) {
			if (getAuthenticationStatus === 'FULFILLED') {
				// Dismiss loading state
				setShowSubmitLoading(false)

				// Reset error message
				setShowLoginError(false)

				// Set user info
				dispatch(
					setPhoneNumber(
						numberUtil.formatPhoneNumberforAPI(
							formikLoginRef?.current.values.phone_number
						)
					)
				)

				// Reset form button
				formikLoginRef?.current?.setSubmitting(false)

				// Show SMS verification dialog
				setIsVerifyDialogOpen(true)

				dispatch(resetAuthenticationStatus())
			} else if (getAuthenticationStatus === 'REJECTED') {
				// Dismiss loading state
				setShowSubmitLoading(false)

				// Reset form button
				formikLoginRef?.current?.setSubmitting(false)

				// Show error message
				setShowLoginError(true)

				dispatch(resetAuthenticationStatus())
			} else if (getAuthenticationStatus === 'PENDING') {
				// Show a loading state
				setShowSubmitLoading(true)
			}
		} else {
			handleAuthSuccessAndNav(dashboardType)
		}
	}, [getAuthenticationStatus])

	useEffect(() => {
		if (getVerifyLoginOTPStatus === 'REJECTED') {
			if (loginErrorCode === 14) {
				setIsVerifyDialogOpen(false)
				setShowLoginError(true)
				dispatch(resetVerifyLoginOTPStatus())
			}
		}
	}, [getVerifyLoginOTPStatus])

	const handleContinue = () => {
		setShowLoginError(false)
		const { phone_number } = formikLoginRef?.current.values
		// Dispatch action to call API to validate phone number and password
		// If authenticate is successful, SMS code is automatically sent to the phone number
		dispatch(
			postPersonAuthenticate({
				phone_number: numberUtil.formatPhoneNumberforAPI(phone_number)
			})
		)
	}

	const phoneNbrFormatter = (value) => {
		const { setFieldValue } = formikLoginRef.current
		setFieldValue('phone_number', numberUtil.formatPhoneNumberProgress(value))
	}

	const handleSMSVerification = (code) => {
		dispatch(
			verifyLoginOTP({
				phone_number: numberUtil.formatPhoneNumberforAPI(phone_number),
				code: code
			})
		)
	}

	const handleResendCode = () => {
		handleContinue()
	}

	function handleAuthSuccessAndNav() {
		dispatch(resetVerifyLoginOTPStatus())

		switch (onboard_status) {
			case PERSON.ONBOARD_STATUS.ADMIN:
				router.push(`/dashboard/admin`)
				break
			case PERSON.ONBOARD_STATUS.ACTIVE_CONTRACT:
				router.push(`/dashboard/member`)
				break
			case PERSON.ONBOARD_STATUS.PENDING_APPROVAL:
				router.push(`/booking/onboard`)
			default:
				break
		}
	}

	// Common styles
	const inputStyle =
		'relative border rounded-md form-control block p-3 w-full text-sm appearance-none focus:outline-none bg-brand-white'
	const inputLabelStyle =
		'capitalize text-brand-charcoal-dark mb-2 tracking-wide w-full'

	return (
		<>
			<Layout
				site={data.site}
				showFooter={false}
				headerType={HEADER.withNav}
				headerVariant={'alternate-white'}
				isLoggedIn={isLoggedIn}
				extraClass='flex flex-col'
			>
				<div className='flex bg-white lg:bg-brand-blue lg:pt-16 items-center flex-col flex-1'>
					<Seo route={data.route} site={data.site} />
					<div className='flex bg-white rounded-3xl justify-center items-center p-8 lg:p-16  lg:w-5/12'>
						<div className='flex flex-col' style={{ width: '100%' }}>
							<Formik
								innerRef={formikLoginRef}
								initialValues={initialValues}
								validateOnBlur={false}
								validateOnChange={false}
								onSubmit={handleContinue}
								validationSchema={validationSchema}
							>
								{(props) => {
									return (
										<>
											<Form className='font-serif font-normal w-full'>
												<h1 className='text-brand-charcoal-dark font-sans font-bold text-3xl text-center lg:text-5xl pb-5'>
													{t('login-or-signup', { ns: 'common' })}
												</h1>
												<div className='mb-10 text-center'>
													{t('well-text-you', {
														ns: 'common'
													})}
													<Link legacyBehavior href='privacy-policy'>
														<a target='_blank' rel='noopener noreferrer'>
															<span className='font-bold hover:cursor-pointer underline text-brand-blue'>
																{' '}
																{t('privacy-policy', {
																	ns: 'booking'
																})}
																.
															</span>
														</a>
													</Link>
												</div>
												{!showLoginError && (
													<>
														<div className='input-container flex flex-col w-full items-start justify-start lg:px-6'>
															<div className={cc([inputLabelStyle])}>
																{t('cell-number', {
																	ns: 'booking'
																})}{' '}
															</div>
															<Field
																component={FormInput}
																label=''
																type='text'
																name='phone_number'
																labelclassname='hidden'
																inputclassname={cc([
																	inputStyle,
																	'focus-within:border-brand-charcoal-light border-brand-medium-grey'
																])}
																height='70px'
																onChange={(e) =>
																	phoneNbrFormatter(e.target.value)
																}
																helperText=''
															/>
															<div className='font-normal text-brand-graphite text-xs normal-case mt-2'>
																{t('sms-required', {
																	ns: 'booking'
																})}
															</div>
															{props.errors.phone_number && (
																<div className='text-sm text-brand-red pt-2 text-center mt-10'>
																	{t('please-enter-a-valid-phone', {
																		ns: 'common'
																	})}
																</div>
															)}
														</div>
														<div className='flex flex-col mt-10 items-center justify-center lg:items-end lg:justify-end w-full lg:px-6'>
															<div className='flex items-center justify-center w-full'>
																{showSubmitLoading && (
																	<div className='flex flex-row items-center justify-center h-full'>
																		<Loading />
																	</div>
																)}
																{!showSubmitLoading && (
																	<CtaButton
																		type='submit'
																		disabled={props.isSubmitting}
																		size='base'
																		className='bg-brand-blue text-white text-sm capitalize rounded-full mt-3 justify-center w-full'
																	>
																		{t('send-code', {
																			ns: 'common'
																		})}
																	</CtaButton>
																)}
															</div>
														</div>
													</>
												)}
												{showLoginError && (
													<>
														<div className='flex items-center justify-center leading-4 gap-2 flex-1 p-6 bg-red-50 rounded-xl'>
															<div className=''>
																<Info size={24} />
															</div>
															<div className=''>
																{t('login-error', {
																	ns: 'common'
																})}
															</div>
														</div>
														<div className='mt-10'>
															{t('questions', {
																ns: 'common'
															})}{' '}
															<br className='inline lg:hidden' />
															{t('give-us-a-call', {
																ns: 'common'
															})}{' '}
															<a
																href='tel:8333807883'
																className='underline cursor-pointer'
															>{`(833) 380-7883`}</a>
														</div>
													</>
												)}
												<ConfirmPhoneNumberDialog
													show={isVerifyDialogOpen}
													title=''
													className={'inline-block bg-brand-light-grey'}
													closeDialog={() => setIsVerifyDialogOpen(false)}
													handleSMSVerification={handleSMSVerification}
													handleResendCode={handleResendCode}
													handleAuthSuccessAndNav={handleAuthSuccessAndNav}
												/>
											</Form>
										</>
									)
								}}
							</Formik>
						</div>
					</div>
				</div>
			</Layout>
		</>
	)
}

export async function getStaticProps({ locale }) {
	// Pass data to the page via props
	return {
		props: {
			...(await serverSideTranslations(locale, TRANSLATIONS)),
			data: {
				route: {
					openGraph: {
						title: 'Login',
						description: 'Stuf Storage Login'
					}
				},
				site: {
					openGraph: {
						description: 'Stuf Storage Login'
					}
				}
			}
		}
	}
}

export default Login
