import cc from 'classcat'
import { Field, Form, Formik } from 'formik'
import { Dialog, Transition } from '@headlessui/react'
import { withTranslation } from 'next-i18next'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { X } from '@phosphor-icons/react'

import { FormInput } from '../components/form-input'
import { resetVerifyOnboardingOTPStatus } from '../slices/bookingSlice'
import { resetVerifyLoginOTPStatus } from '../slices/rootSlice'
import { resetPostVerifyLockCodeOTPStatus } from '../slices/memberDashboardSlice'
import { TRANSLATIONS } from '../util/constants'

function ConfirmPhoneNumberDialog({
	title,
	handleSMSVerification,
	handleResendCode,
	handleAuthSuccessAndNav,
	t,
	i18n,
	...props
}) {
	let dispatch = useDispatch()
	const formikDialogRef = useRef()
	let phone_number = useSelector((state) => state.root.user.phone_number)
	let getVerifyLoginOTPStatus = useSelector(
		(state) => state.root.getVerifyLoginOTPStatus
	)
	let postVerifyOnboardingOTPStatus = useSelector(
		(state) => state.booking.postVerifyOnboardingOTPStatus
	)
	let postVerifyLockCodeOTPStatus = useSelector(
		(state) => state.memberDashboard.postVerifyLockCodeOTPStatus
	)
	const loginErrorCode = useSelector((state) => state.root.loginErrorCode)

	const [verificationError, setVerificationError] = useState(false)

	let initialValues = {
		digit1: '',
		digit2: '',
		digit3: '',
		digit4: '',
		digit5: '',
		digit6: ''
	}

	useEffect(() => {
		dispatch(resetVerifyLoginOTPStatus())
		dispatch(resetVerifyOnboardingOTPStatus())
		dispatch(resetPostVerifyLockCodeOTPStatus())
	}, [])

	// TODO Move API response actions to the calling Component. Have these call a function to clear the form and handle
	// their own error states
	useEffect(() => {
		// OTP verification happens during onboarding flow and generalized login flow
		if (
			getVerifyLoginOTPStatus === 'FULFILLED' ||
			postVerifyOnboardingOTPStatus === 'FULFILLED' ||
			postVerifyLockCodeOTPStatus === 'FULFILLED'
		) {
			// Reset error message
			setVerificationError(undefined)

			// Reset form content
			formikDialogRef?.current?.resetForm()

			// Reset form button
			formikDialogRef?.current?.setSubmitting(false)

			// If success, close popup
			handleClose()

			handleAuthSuccessAndNav()
		} else if (
			getVerifyLoginOTPStatus === 'REJECTED' ||
			postVerifyOnboardingOTPStatus === 'REJECTED' ||
			postVerifyLockCodeOTPStatus === 'REJECTED'
		) {
			if (loginErrorCode !== 14) {
				// Reset form button
				formikDialogRef?.current?.setSubmitting(false)
				// Reset form content
				formikDialogRef?.current?.resetForm()
				// Focus first digit
				document.getElementById('digit1').focus()

				// Show error message
				const errMsg = `${t('incorrect-code', { ns: 'common' })}. ${t(
					'pls-try-again',
					{ ns: 'common' }
				)}.`
				setVerificationError(errMsg)
			}
		} else if (
			getVerifyLoginOTPStatus === 'PENDING' ||
			postVerifyOnboardingOTPStatus === 'PENDING' ||
			postVerifyLockCodeOTPStatus === 'PENDING'
		) {
			// TODO Show a loading state
		}
	}, [
		getVerifyLoginOTPStatus,
		postVerifyOnboardingOTPStatus,
		postVerifyLockCodeOTPStatus
	])

	const digitFormatter = (fieldName, value) => {
		const { setFieldValue, values, submitForm } = formikDialogRef.current
		const numericRegex = /^[0-9]$/

		// Only allow 1 numeric digit per input box
		if (value && numericRegex.test(value)) {
			setVerificationError(false)
			setFieldValue(fieldName, value).then(() => {
				// Auto submit form after entering digit 6
				if (
					(values.digit1?.length ? 1 : 0) +
						(values.digit2?.length ? 1 : 0) +
						(values.digit3?.length ? 1 : 0) +
						(values.digit4?.length ? 1 : 0) +
						(values.digit5?.length ? 1 : 0) +
						(values.digit6?.length ? 1 : 0) >=
					5
				) {
					submitForm()
				} else {
					// Set the focus to the next input box
					let digit = fieldName.split('digit')
					digit = digit[1][0].toString()
					switch (digit) {
						case '1':
							document.getElementById('digit2').focus()
							break
						case '2':
							document.getElementById('digit3').focus()
							break
						case '3':
							document.getElementById('digit4').focus()
							break
						case '4':
							document.getElementById('digit5').focus()
							break
						case '5':
							document.getElementById('digit6').focus()
							break
						default:
							break
					}
				}
			})
		} else {
			if (value.length <= 1) {
				setFieldValue(fieldName, '')
			}
		}
	}

	const handleClose = () => {
		// Reset error message
		setVerificationError(undefined)

		// Reset form content
		formikDialogRef?.current?.resetForm()

		// Reset form button
		formikDialogRef?.current?.setSubmitting(false)

		// Close popup
		props.closeDialog()
	}

	const handleDone = (values, actions) => {
		const digitString = `${values.digit1}${values.digit2}${values.digit3}${values.digit4}${values.digit5}${values.digit6}`

		handleSMSVerification(digitString)
	}

	// Common styles
	const inputStyle =
		'relative border rounded-md form-control block p-3 w-12 text-center mr-3 text-sm appearance-none focus:outline-none bg-brand-white'
	const inputLabelStyle =
		'font-bold uppercase text-brand-charcoal-dark mb-2 tracking-wide w-full'

	return (
		<Transition show={props.show} as={Fragment}>
			<Dialog
				as='div'
				className='fixed inset-0 overflow-y-auto bg-brand-white rounded-xl shadow bg-opacity-30'
				style={{ zIndex: '100' }}
				open={props.show}
				onClose={() => {}}
			>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-30' />
				</Transition.Child>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div>
						<Formik
							innerRef={formikDialogRef}
							initialValues={initialValues}
							validateOnBlur={false}
							onSubmit={handleDone}
						>
							{(props) => {
								return (
									<Form className='flex font-serif'>
										<div
											className='lg:w-1/2 inline-block p-6 my-8 overflow-hidden text-center align-middle transition-all transform bg-white shadow-xl rounded-2xl'
											style={{
												height: 'auto',
												marginLeft: 'auto',
												marginRight: 'auto'
											}}
										>
											<div
												className='flex flex-row mb-4 items-center'
												style={{ width: '100%' }}
											>
												<div
													className='hover:cursor-pointer relative'
													style={{ width: '10%', zIndex: '110' }}
													onClick={handleClose}
												>
													<X size={20} weight='bold' />
												</div>
												<div
													className='uppercase font-serif font-bold text-center relative'
													style={{
														marginLeft: '-10%',
														fontSize: '16px',
														width: '100%'
													}}
												>
													{t('confirm-your-number', { ns: 'common' })}
												</div>
											</div>
											<div
												className='border-b border-slate-50 my-3'
												style={{ width: '100%' }}
											/>
											<div
												className='flex flex-col mt-10'
												style={{
													height: 'auto',
													width: '100%'
												}}
											>
												<div className='text-lg font-sans font-semibold mb-2'>
													{title}
												</div>
												<div className='text-sm'>
													{t('confirm-text', {
														ns: 'common'
													})}{' '}
													<span className=''>{phone_number} </span>
												</div>
												<div className='flex flex-row items-center justify-center mt-6'>
													<Field
														id='digit1'
														component={FormInput}
														label=''
														name='digit1'
														inputMode='numeric'
														labelclassname='hidden'
														inputclassname={cc([
															inputStyle,
															verificationError
																? 'border-brand-red focus-within:border-brand-red'
																: 'border-brand-medium-grey focus-within:border-brand-charcoal-light'
														])}
														height='150px'
														onChange={(e) =>
															digitFormatter('digit1', e.target.value)
														}
													/>
													<Field
														id='digit2'
														component={FormInput}
														label=''
														name='digit2'
														inputMode='numeric'
														labelclassname='hidden'
														inputclassname={cc([
															inputStyle,
															verificationError
																? 'border-brand-red focus-within:border-brand-red'
																: 'border-brand-medium-grey focus-within:border-brand-charcoal-light'
														])}
														height='150px'
														onChange={(e) =>
															digitFormatter('digit2', e.target.value)
														}
													/>
													<Field
														id='digit3'
														component={FormInput}
														label=''
														name='digit3'
														inputMode='numeric'
														labelclassname='hidden'
														inputclassname={cc([
															inputStyle,
															verificationError
																? 'border-brand-red focus-within:border-brand-red'
																: 'border-brand-medium-grey focus-within:border-brand-charcoal-light'
														])}
														height='150px'
														onChange={(e) =>
															digitFormatter('digit3', e.target.value)
														}
													/>
													<Field
														id='digit4'
														component={FormInput}
														label=''
														name='digit4'
														inputMode='numeric'
														labelclassname='hidden'
														inputclassname={cc([
															inputStyle,
															verificationError
																? 'border-brand-red focus-within:border-brand-red'
																: 'border-brand-medium-grey focus-within:border-brand-charcoal-light'
														])}
														height='150px'
														onChange={(e) =>
															digitFormatter('digit4', e.target.value)
														}
													/>
													<Field
														id='digit5'
														component={FormInput}
														label=''
														name='digit5'
														inputMode='numeric'
														labelclassname='hidden'
														inputclassname={cc([
															inputStyle,
															verificationError
																? 'border-brand-red focus-within:border-brand-red'
																: 'border-brand-medium-grey focus-within:border-brand-charcoal-light'
														])}
														height='150px'
														onChange={(e) =>
															digitFormatter('digit5', e.target.value)
														}
													/>
													<Field
														component={FormInput}
														label=''
														name='digit6'
														inputMode='numeric'
														labelclassname='hidden'
														inputclassname={cc([
															inputStyle,
															verificationError
																? 'border-brand-red focus-within:border-brand-red'
																: 'border-brand-medium-grey focus-within:border-brand-charcoal-light'
														])}
														height='150px'
														onChange={(e) =>
															digitFormatter('digit6', e.target.value)
														}
													/>
												</div>
											</div>
											{verificationError && (
												<div className='text-brand-red text-xs pt-2 pb-4'>
													{verificationError}
												</div>
											)}
											<div
												className='text-sm pt-4 pb-6'
												onClick={() => {
													// Let login parent handle dispatching action to resend the code
													handleResendCode()
													// Reset digits
													formikDialogRef?.current?.resetForm()
												}}
											>
												{t('didnt-get-code', {
													ns: 'common'
												})}{' '}
												<span className='underline hover:cursor-pointer'>
													{t('resend-code', {
														ns: 'common'
													})}{' '}
												</span>
											</div>
										</div>
									</Form>
								)
							}}
						</Formik>
					</div>
				</Transition.Child>
			</Dialog>
		</Transition>
	)
}

export default withTranslation(TRANSLATIONS)(ConfirmPhoneNumberDialog)
